import React from 'react';
import { Row, Col, Typography, Card } from 'antd';
import './Disclaimer.css';
import RectangleGreen from '../../assets/Disclaimer/Rectangle 4856.png'
import RectangleYellow from '../../assets/Disclaimer/Rectangle 4854.png'
import Rectangle from '../../assets/Disclaimer/Rectangle 4855.png'
import DisclaimerNotebookImg from '../../assets/Disclaimer/light-background.png'

const { Title, Paragraph, Text } = Typography;

const Disclaimer = () => {
  return (
    <>
     <div className="w-full" style={{marginTop: '90px'}}>
  <div style={{
    background: 'linear-gradient(to right, #f0f2f5, #dfe2b3)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '40px 62px',
  }}>
    <div style={{ flexShrink: 0 }}>.
      <Title level={2} style={{
        color: '#022146',
        fontSize: '44px',
        margin: 0,
        fontWeight: '600'
      }}>
        Disclaimer
      </Title>
    </div>

    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',  
      width: '50%',
    }}>
      <img
        preview={false}
        src={RectangleGreen}
        alt="Teal rectangle"
        className="rectangle1"
        style={{
          transform: 'rotate(4deg)',
          maxWidth: '150px', 
        }}
      />

      <img
      preview={false}
      src={DisclaimerNotebookImg}
      alt="Teal rectangle"
      className="rectangle1"
      style={{
        // transform: 'rotate(45deg)',
        maxWidth: '334px', 
        position: 'absolute',
          top: '0px', 
          left:'70px',
          zIndex:2
      }}
    />
      
      {/* Yellow rectangle */}
      <img
        preview={false}
        src={RectangleYellow}
        alt="Yellow rectangle"
        className="rectangle2"
        style={{
          position: 'absolute',
          top: '34px', 

          transform: 'rotate(7deg)',
          maxWidth: '150px', 
        }}
      />
      
      <img
        preview={false}
        src={Rectangle}
        alt="Light blue rectangle"
        className="rectangle3"
        style={{
          transform: 'rotate(150deg)',
          maxWidth: '160px', 
          position: 'absolute',
          top: '-28px',
          left:'243px'
        }}
      />
    </div>
  </div>
</div>
  
    <div className="disclaimer-container">
      <Row gutter={[16, 16]} className="content-section">
        {/* <Col xs={24} md={12} >
          <img 
            src={ imgDisclaimer}
            alt="Business discussion"
            className="section-image"
          />
        </Col> */}
        <Col xs={24}  className="text-col">
          <Card bordered={false} className="content-card">
            <Title level={3}> Relationship of Apex Defi Labs and Defi Assets LLC</Title>
            <Paragraph   style={{ textAlign: 'justify' }}>
              <Text strong>Defi Assets LLC </Text>is a fully owned subsidiary of <Text strong> Apex Defi Labs ("Apex")</Text> 
            </Paragraph>
            <Paragraph   style={{ textAlign: 'justify' }}>
              The websites <Text strong>ApexDefiLabs.com</Text>, <Text strong>DefiAssets.com</Text>, <Text strong>VeepCapital.com</Text>, <Text strong>PioneerShift.com</Text>, and <Text strong>SingleFamilyCF.com</Text> (collectively, the "Sites") are affiliated platforms and/or brands owned or operated by Apex or its subsidiaries.
            </Paragraph>
            <Paragraph   style={{ textAlign: 'justify' }}>
              <Text strong>Apex Defi Labs</Text> is not a registered broker-dealer or investment adviser. All broker-dealer services, including the sale of tokenized securities, are offered <Text strong>exclusively</Text>  through Regiment Securities, LLC.
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Row gutter={[32, 16]} className="content-section reverse-on-mobile">
        <Col xs={24} className="text-col">
          <Card bordered={false} className="content-card">
            <Title level={3}>Apex Defi Labs' Partnership with Regiment Securities, LLC</Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
            Apex Defi Labs is not a registered broker-dealer or investment adviser. All broker-dealer services, including the sale of securities that may be additionally represented by tokens, are offered and conducted exclusively through Regiment Securities, LLC, a FINRA-registered broker-dealer and member of SIPC. Apex has entered into a strategic partnership with Regiment to make certain securities offerings available to accredited and qualified investors. Apex's role is limited to developing and operating blockchain or other technology-based solutions that may facilitate investments, including providing Issuer-approved tokens that represent securities, but Apex does not solicit, recommend, or effect the sale or purchase of any securities.            </Paragraph>
          </Card>
        </Col>
        {/* <Col xs={24} md={12} className="image-col">
          <img 
            src={BusinessPartnerShip}
            alt="Business partnership"
            className="section-image"
          />
        </Col> */}
      </Row>
      <Row gutter={[32, 32]} className="content-section">
        {/* <Col xs={24} md={12} className="image-col">
          <img 
            src={investmentAnalysis}
            alt="Investment analysis"
            className="section-image"
          />
        </Col> */}
        <Col xs={24} className="text-col">
          <Card bordered={false} className="content-card">
            <Title level={3}>No Investment, Legal, or Tax Advice</Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              All information provided on Veepcapital.com or any other Apex or Defi Assets LLC platform, including websites, marketing materials, or other communications (collectively, the "Site"), is strictly for informational purposes only and should not be construed as investment, legal, accounting, or tax advice. Neither Apex nor Regiment provides investment advice to any individual or entity, and no communication on or through the Site is intended to be, or should be interpreted as, a recommendation or endorsement to buy, sell, or hold any security or other financial product. You are solely responsible for conducting any legal, accounting, business, tax, or other due diligence review concerning the merits and risks of any potential transaction. Before making any investment or other financial decision, you should consult with your own professional advisors.
            </Paragraph>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Card bordered={false} className="content-card">
            <Title level={3}>Nature of Offerings</Title>
            <Title level={4}>Private Placements</Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              All information provided on Veepcapital.com or any other Apex or Defi Assets LLC platform, including websites, marketing materials, or other communications (collectively, the "Site"), is strictly for informational purposes only and should not be construed as investment, legal, accounting, or tax advice. Neither Apex nor Regiment provides investment advice to any individual or entity, and no communication on or through the Site is intended to be, or should be interpreted as, a recommendation or endorsement to buy, sell, or hold any security or other financial product. You are solely responsible for conducting any legal, accounting, business, tax, or other due diligence review concerning the merits and risks of any potential transaction. Before making any investment or other financial decision, you should consult with your own professional advisors.
            </Paragraph>
          </Card>
        </Col>
      </Row>

        <Row gutter={[32,32]}>
          <Col span={24}>
            <Card bordered={false} className="content-card">
              <Title level={4}>High Degree of Risk</Title>
              <Paragraph  style={{ textAlign: 'justify' }}>
                Investments in private placements, including tokenized securities, are speculative and involve a high degree of risk, including the possible loss of your entire investment. Such investments are often illiquid and not suitable for investors who cannot afford to lose their invested capital or who need liquidity.
              </Paragraph>
              <Title level={4}>No Assurance of Performance</Title>
              <Paragraph  style={{ textAlign: 'justify' }}>
                Any forward-looking statements, financial projections, or target returns referenced on the Site are estimates only, provided by the issuer or sponsor of the offering, and no representation or warranty is made that any transaction will achieve results or performance similar to those shown. Past performance is not indicative of future results.
              </Paragraph>
              <Title level={4}>Issuer Information</Title>
              <Paragraph  style={{ textAlign: 'justify' }}>
                All information regarding any specific offering (including but not limited to business plans, risk factors, fees, and expenses) is provided solely by the issuer or sponsor of that offering. Apex, Defi Assets LLC, and Regiment do not independently verify or warrant the accuracy or completeness of such information.
              </Paragraph>
            </Card>
          </Col>
        </Row>

      <Row>
        {/* <Col xs={24} md={12}>
      
          <Card bordered={false}>
          
            <img
              src={RelationshipDiagram}
              alt="Relationship Diagram"
              className="w-full"
              preview={false}
            />
          </Card>
        </Col> */}
        
        <Col xs={24} >
       
          <Card className='text' bordered={false}>
          <Title level={3} className="text-blue-800">
              No Endorsement; Third-Party Links
            </Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              Apex DeFi Labs, DeFi Assets LLC, and Regiment do not endorse or 
              recommend any particular issuer, offering, or investment strategy.
            </Paragraph>
            <Paragraph  style={{ textAlign: 'justify' }}>
              Any links to third-party websites or references to third-party content 
              are provided solely for convenience. Apex DeFi Assets LLC, and 
              Regiment have no control over such websites or content and assume 
              no responsibility for their accuracy or compliance.
            </Paragraph>
            <Paragraph  style={{ textAlign: 'justify' }}>
              References to or reproductions of third-party articles or media do not 
              constitute endorsement or approval by Apex, DeFi Assets LLC, or Regiment.
            </Paragraph>
          </Card>
        </Col>
      </Row>

      
      <Row>
        <Col span={24}>
          <Card bordered={false} className="content-card">
            <Title level={3}>Securities Regulatory Disclosures</Title>
            <Paragraph>
            <Title level={4} className="mt-4">Regulatory Status</Title>
           
              Regiment Securities, LLC is a broker-dealer registered with the SEC and a member of FINRA/SIPC. You can verify Regiment's status on FINRA's BrokerCheck.
            </Paragraph>

            <Title level={4} className="mt-4">Exempt from Registration</Title>
            <Paragraph>
              Tokenized securities offered through Regiment may be sold in reliance on exemptions from the registration requirements of the Securities Act of 1933, as amended, and other applicable securities laws. These securities cannot be offered or sold in the United States absent registration or an applicable exemption.
            </Paragraph>

            <Title level={4} className="mt-4">Risk of Total Loss</Title>
            <Paragraph>
              Neither the SEC nor any federal or state securities commission or regulatory authority has recommended or approved any investment offered on the Site, nor has any such authority confirmed the accuracy or adequacy of any offering materials. Investors should be prepared to bear the entire loss of their investment.
            </Paragraph>

            <Title level={4} className="mt-4">No Guarantee of Compliance</Title>
            <Paragraph>
            While Regiment is registered with the SEC and is a member of FINRA, such membership and registration do not imply that Regiment or any related entity has been endorsed, approved, or guaranteed by those regulators. Compliance with all applicable laws, rules, and regulations is an ongoing obligation of the firm and its registered representatives.
            </Paragraph>
          </Card>
        </Col>
      </Row>
      
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card bordered={false}>
            <Title level={3} className="text-blue-800">
              Forward-Looking Statements
            </Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              Some statements on the Site may contain "forward-looking" information that involves substantial risks and uncertainties. Such statements are often identified by terms like "believe," "anticipate," "expect," "estimate," "intend," "project," "will," "may," "should," "could," and similar expressions. Any forward-looking statements are based on assumptions that may prove incorrect, and no representation is made that such performance will come to pass.
            </Paragraph>

            <Title level={4} className="mt-4">Uncertainty of Liability</Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              Tokenized securities (and private placements generally) are typically illiquid. There may be no active trading markets for these investments, and there is no assurance that a secondary market for buying or selling tokenized securities will develop. You should be prepared to hold any investment for an extensive period and potentially lose your entire investment.
            </Paragraph>

            <Title level={4} className="mt-4">Use of Technology and Blockchain</Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              Certain offerings may involve the issuance or transfer of securities via blockchain technology. The regulatory framework and legal treatment of digital assets and blockchain technologies are evolving rapidly, and there can be no assurance as to how laws, regulations, or government policies may impact your ability to hold, transfer, or transact in such securities. Potential investors should carefully consider these and other technology-specific risks before investing.
            </Paragraph>

            <Title level={4} className="mt-4">No Solicitation or Recommendation</Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
            No communication by Apex, Defi Assets LLC, Regiment, or any of their affiliates through the Site or any other medium is intended as, or should be construed as, a <Text strong>solicitation </Text> of an offer to buy or sell, nor a<Text strong> recommendation</Text> recommendation to purchase, sell, or hold any security or tokenized security.            </Paragraph>
          </Card>
        </Col>

        <Col span={24}>
          <Card bordered={false}>
            <Title level={3} className="text-blue-800">
              Investor Responsibilities
            </Title>
            <Row gutter={[24, 24]} align="middle">
              {/* <Col xs={24} md={12}>
                <img
                  src={investorMeeting}
                  alt="Investor Meeting"
                  preview={false}
                  className="w-full"
                />
              </Col> */}
              <Col xs={24}>
                <div className="space-y-4">
                  <div>
                    <Title level={4}>Before deciding to invest, you should:</Title>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>
                        <Text strong>Conduct Your Own Investigation</Text>
                        <Paragraph>Carefully review all offering materials and risk disclosures.</Paragraph>
                      </li>
                      <li>
                        <Text strong>Consider Your Objectives and Circumstances</Text>
                        <Paragraph>Evaluate whether the investment aligns with your financial situation, risk tolerance, and investment objectives.</Paragraph>
                      </li>
                      <li>
                        <Text strong>Consult Advisers</Text>
                        <Paragraph>Seek appropriate advice from your own legal, tax, financial, and investment professionals.</Paragraph>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card bordered={false}>
            <Title level={3} className="text-blue-800">
              No Warranties or Liability
            </Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              Neither Apex DeFi Labs Inc, DeFi Assets LLC, Regiment Securities LLC, nor any of their respective officers, directors, employees, or affiliates, makes any express or implied representation or warranty as to: (i) the accuracy, completeness, or adequacy of any information contained on the Site; (ii) the circumstances would Apex DeFi Labs Inc, DeFi Assets LLC, Regiment or their affiliates be liable for any direct, indirect, incidental, consequential or other damages arising out of your use of or reliance on the information provided.
            </Paragraph>
          </Card>
        </Col>

        <Col span={24}>
          <Card bordered={false}>
            <Title level={3} className="text-blue-800">
              Governing Terms
            </Title>
            <Paragraph  style={{ textAlign: 'justify' }}>
              Your access to and use of the Site is subject to our Terms of Service and Privacy Policy. By accessing the Site and any pages thereof, you agree to be bound by these terms and policies. If you do not agree with the Terms, you should not access the Site or any information or materials contained therein.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Disclaimer;